import { initializeApp, getApps } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { useAuthStore } from '@/stores/AuthStore';
import {
  getDatabase,
  ref,
  onValue,
  get,
} from 'firebase/database';

export default defineNuxtPlugin(async (nuxtApp) => {
  if (process.client) {
    const config = useRuntimeConfig();
    const authStore = useAuthStore(nuxtApp.$pinia);

    const firebaseConfig = {
      apiKey: config.public.apiKey,
      authDomain: config.public.authDomain,
      projectId: config.public.projectId,
      storageBucket: config.public.storageBucket,
      messagingSenderId: config.public.messagingSenderId,
      appId: config.public.appId,
    };

    const apps = getApps();
    const app = !apps.length ? initializeApp(firebaseConfig) : apps[0];

    const messaging = getMessaging(app);
    onMessage(messaging, async (payload) => {
      console.log('message received', payload);
      if (
        (!authStore.pushNotificationData && authStore.sessionTimer) ||
        payload.data.type === 'scheduled-session-time'
      ) {
        authStore.pushNotificationData = payload.data;
        await authStore.getProfile();
      } else if (payload.data && payload.data.type) {
        authStore.pushNotificationData = payload.data;
        setTimeout(() => {
          if (
            authStore.pushNotificationData && authStore.pushNotificationData.type !== 'ending-session-request'
          ) {
            authStore.pushNotificationData = null;
          }
        }, 30000);
      }
    });
    nuxtApp.vueApp.provide('messaging', messaging);

    const getCoachesFromRealTimeDb = async () => {
      const db = getDatabase();
      const coachesRef = ref(
        db,
        `${config.public.appEnv}-coaches-status/`
      );
      const coachesSnapshot = await get(coachesRef);
      let currentCoaches = coachesSnapshot.exists()
        ? coachesSnapshot.val()
        : [];
    
      const updateCoachesData = async (coaches) => {
        authStore.coachesFromRealTimeDb = [];
        if (Object.keys(coaches).length > 0) {
          for (const key in coaches) {
            authStore.coachesFromRealTimeDb.push(coaches[key]);
          }
        }
      };

      updateCoachesData(currentCoaches);
      // Monitor connection state changes
      onValue(coachesRef, async (snapshot) => {
        if (snapshot.val()) {
          // Client is connected to Firebase
          // Update the user's status and last_changed timestamp
          await updateCoachesData(snapshot.val());
        }
      });
    };

    return {
      provide: {
        getCoachesFromRealTimeDb,
      },
    };
  }
});
