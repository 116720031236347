import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "/app/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_RdadCF4ArJ from "/app/node_modules/nuxt-aos/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import injectGTM_client_67yTNmXzFw from "/app/plugins/injectGTM.client.js";
import firebase_client_nS52P0yitD from "/app/plugins/firebase.client.js";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import setHeaders_client_w5cjUbfQIY from "/app/plugins/setHeaders.client.js";
import useAgora_client_xeLOaij598 from "/app/plugins/useAgora.client.js";
import vuetify_8NhHJigKc1 from "/app/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  plugin_RdadCF4ArJ,
  chunk_reload_client_UciE0i6zes,
  injectGTM_client_67yTNmXzFw,
  firebase_client_nS52P0yitD,
  sentry_client_GoGQuZo4Et,
  setHeaders_client_w5cjUbfQIY,
  useAgora_client_xeLOaij598,
  vuetify_8NhHJigKc1
]