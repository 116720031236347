import { default as SignInGAVDNwL85UMeta } from "/app/pages/auth/SignIn.vue?macro=true";
import { default as indext53WuZVyKNMeta } from "/app/pages/auth/activate-account/index.vue?macro=true";
import { default as forget_45password679SnzZu8qMeta } from "/app/pages/auth/forget-password.vue?macro=true";
import { default as indexsBMDvY1668Meta } from "/app/pages/auth/reset-password/[token]/index.vue?macro=true";
import { default as signUp2tw8R9WfRFMeta } from "/app/pages/auth/signUp.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexR4tZFuK9MgMeta } from "/app/pages/coach/[id]/index.vue?macro=true";
import { default as indexEoQ3kwClNdMeta } from "/app/pages/coach/details/index.vue?macro=true";
import { default as indexPxCCMmRByEMeta } from "/app/pages/coach/index.vue?macro=true";
import { default as indexmBploIUwxeMeta } from "/app/pages/contact-us/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as statusfKAaZAFhlLMeta } from "/app/pages/orders/status.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as indexyhvSHwL9mvMeta } from "/app/pages/redirect/index.vue?macro=true";
import { default as indexUmqc265mShMeta } from "/app/pages/room/index.vue?macro=true";
import { default as indexrrsszXZdgAMeta } from "/app/pages/sessions/index.vue?macro=true";
import { default as _91id_93IyQSNGLm0fMeta } from "/app/pages/sessions/session-details/[id].vue?macro=true";
import { default as indexTtjA6pYSlYMeta } from "/app/pages/termsAndConditions/index.vue?macro=true";
import { default as indexzk34bxS2TyMeta } from "/app/pages/wallet/index.vue?macro=true";
export default [
  {
    name: SignInGAVDNwL85UMeta?.name ?? "auth-SignIn",
    path: SignInGAVDNwL85UMeta?.path ?? "/auth/SignIn",
    meta: SignInGAVDNwL85UMeta || {},
    alias: SignInGAVDNwL85UMeta?.alias || [],
    redirect: SignInGAVDNwL85UMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/SignIn.vue").then(m => m.default || m)
  },
  {
    name: indext53WuZVyKNMeta?.name ?? "auth-activate-account",
    path: indext53WuZVyKNMeta?.path ?? "/auth/activate-account",
    meta: indext53WuZVyKNMeta || {},
    alias: indext53WuZVyKNMeta?.alias || [],
    redirect: indext53WuZVyKNMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/activate-account/index.vue").then(m => m.default || m)
  },
  {
    name: forget_45password679SnzZu8qMeta?.name ?? "auth-forget-password",
    path: forget_45password679SnzZu8qMeta?.path ?? "/auth/forget-password",
    meta: forget_45password679SnzZu8qMeta || {},
    alias: forget_45password679SnzZu8qMeta?.alias || [],
    redirect: forget_45password679SnzZu8qMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/forget-password.vue").then(m => m.default || m)
  },
  {
    name: indexsBMDvY1668Meta?.name ?? "auth-reset-password-token",
    path: indexsBMDvY1668Meta?.path ?? "/auth/reset-password/:token()",
    meta: indexsBMDvY1668Meta || {},
    alias: indexsBMDvY1668Meta?.alias || [],
    redirect: indexsBMDvY1668Meta?.redirect || undefined,
    component: () => import("/app/pages/auth/reset-password/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: signUp2tw8R9WfRFMeta?.name ?? "auth-signUp",
    path: signUp2tw8R9WfRFMeta?.path ?? "/auth/signUp",
    meta: signUp2tw8R9WfRFMeta || {},
    alias: signUp2tw8R9WfRFMeta?.alias || [],
    redirect: signUp2tw8R9WfRFMeta?.redirect || undefined,
    component: () => import("/app/pages/auth/signUp.vue").then(m => m.default || m)
  },
  {
    name: index7ZtduOIoVXMeta?.name ?? "checkout",
    path: index7ZtduOIoVXMeta?.path ?? "/checkout",
    meta: index7ZtduOIoVXMeta || {},
    alias: index7ZtduOIoVXMeta?.alias || [],
    redirect: index7ZtduOIoVXMeta?.redirect || undefined,
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexR4tZFuK9MgMeta?.name ?? "coach-id",
    path: indexR4tZFuK9MgMeta?.path ?? "/coach/:id()",
    meta: indexR4tZFuK9MgMeta || {},
    alias: indexR4tZFuK9MgMeta?.alias || [],
    redirect: indexR4tZFuK9MgMeta?.redirect || undefined,
    component: () => import("/app/pages/coach/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEoQ3kwClNdMeta?.name ?? "coach-details",
    path: indexEoQ3kwClNdMeta?.path ?? "/coach/details",
    meta: indexEoQ3kwClNdMeta || {},
    alias: indexEoQ3kwClNdMeta?.alias || [],
    redirect: indexEoQ3kwClNdMeta?.redirect || undefined,
    component: () => import("/app/pages/coach/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexPxCCMmRByEMeta?.name ?? "coach",
    path: indexPxCCMmRByEMeta?.path ?? "/coach",
    meta: indexPxCCMmRByEMeta || {},
    alias: indexPxCCMmRByEMeta?.alias || [],
    redirect: indexPxCCMmRByEMeta?.redirect || undefined,
    component: () => import("/app/pages/coach/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBploIUwxeMeta?.name ?? "contact-us",
    path: indexmBploIUwxeMeta?.path ?? "/contact-us",
    meta: indexmBploIUwxeMeta || {},
    alias: indexmBploIUwxeMeta?.alias || [],
    redirect: indexmBploIUwxeMeta?.redirect || undefined,
    component: () => import("/app/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders",
    path: indexG1Ehu1sqkUMeta?.path ?? "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect || undefined,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: statusfKAaZAFhlLMeta?.name ?? "orders-status",
    path: statusfKAaZAFhlLMeta?.path ?? "/orders/status",
    meta: statusfKAaZAFhlLMeta || {},
    alias: statusfKAaZAFhlLMeta?.alias || [],
    redirect: statusfKAaZAFhlLMeta?.redirect || undefined,
    component: () => import("/app/pages/orders/status.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile",
    path: indexaeouN7Kzh7Meta?.path ?? "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect || undefined,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexyhvSHwL9mvMeta?.name ?? "redirect",
    path: indexyhvSHwL9mvMeta?.path ?? "/redirect",
    meta: indexyhvSHwL9mvMeta || {},
    alias: indexyhvSHwL9mvMeta?.alias || [],
    redirect: indexyhvSHwL9mvMeta?.redirect || undefined,
    component: () => import("/app/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: indexUmqc265mShMeta?.name ?? "room",
    path: indexUmqc265mShMeta?.path ?? "/room",
    meta: indexUmqc265mShMeta || {},
    alias: indexUmqc265mShMeta?.alias || [],
    redirect: indexUmqc265mShMeta?.redirect || undefined,
    component: () => import("/app/pages/room/index.vue").then(m => m.default || m)
  },
  {
    name: indexrrsszXZdgAMeta?.name ?? "sessions",
    path: indexrrsszXZdgAMeta?.path ?? "/sessions",
    meta: indexrrsszXZdgAMeta || {},
    alias: indexrrsszXZdgAMeta?.alias || [],
    redirect: indexrrsszXZdgAMeta?.redirect || undefined,
    component: () => import("/app/pages/sessions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IyQSNGLm0fMeta?.name ?? "sessions-session-details-id",
    path: _91id_93IyQSNGLm0fMeta?.path ?? "/sessions/session-details/:id()",
    meta: _91id_93IyQSNGLm0fMeta || {},
    alias: _91id_93IyQSNGLm0fMeta?.alias || [],
    redirect: _91id_93IyQSNGLm0fMeta?.redirect || undefined,
    component: () => import("/app/pages/sessions/session-details/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTtjA6pYSlYMeta?.name ?? "termsAndConditions",
    path: indexTtjA6pYSlYMeta?.path ?? "/termsAndConditions",
    meta: indexTtjA6pYSlYMeta || {},
    alias: indexTtjA6pYSlYMeta?.alias || [],
    redirect: indexTtjA6pYSlYMeta?.redirect || undefined,
    component: () => import("/app/pages/termsAndConditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexzk34bxS2TyMeta?.name ?? "wallet",
    path: indexzk34bxS2TyMeta?.path ?? "/wallet",
    meta: indexzk34bxS2TyMeta || {},
    alias: indexzk34bxS2TyMeta?.alias || [],
    redirect: indexzk34bxS2TyMeta?.redirect || undefined,
    component: () => import("/app/pages/wallet/index.vue").then(m => m.default || m)
  }
]